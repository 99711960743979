import React from 'react'
import ReactMarkdown from "react-markdown"
import Title from './title'
import { Link, navigate } from "gatsby"

const LandingHerov3 = ({hero}) => {

    const{tagline,heading,body,image,link} = hero[0]
    const btnID = "btn-landbeginsurvey-" + link + "-id" 

    return (
        <div class="max-w-7xl mx-auto my-0 relative py-0 px-6 scmd:px-1">
            {/* parent container */}
            <div class="flex flex-wrap flex-start">
                {/* left hero column */}
                <div class="w-full pt-24 scmd:pt-0 scmd:w-1/2 mb-4 flex-grow-0 flex-shrink-0 scmd:px-5 text-gray-600">
                        {/* heading section */}
                        <Title heading={tagline} />
                        <h1 class="mb-6">{heading}</h1>
                        {/* body section */}
                        <div class="hero">
                            <ReactMarkdown source={body} />
                        </div>

                        <div class="flex text-xl mx-auto justify-center scmd:justify-start content-center py-4 scmd:py-10">
                            <button id={btnID} onClick={()=>{navigate(`/${link}#secondPage`)}} class="btn-outline-primary rounded shadow-md transition duration-500 ease-in-out hover:shadow-2xl transform hover:-translate-y-1 hover:scale-110">
                                <span class="text-lg mx-6">Get Started</span>
                            </button>
                        </div>                        
                </div>
                    {/* right hero column */}
                    {/* image section */}
                    <div class="w-full scmd:w-1/2 pt-4 flex-grow-0 scmd:px-6 flex-shrink-0 pb-10 scmd:pb-0">
                        <div class="device-wrapper">
                            <div class="device z-10" data-device="iPadPro" data-orientation="landscape" data-color="black">
                                <div class="screen">
                                    {/* <!-- PUT CONTENTS HERE --> */}
                                        <video class="w-full h-full z-20 relative object-fill" aria-describedby="stickchart-explainer-video" autoPlay loop muted playsInline preload="none"> 
                                            <source src={image.publicURL} type="video/mp4"></source>
                                        </video>
                                </div>
                                <div class="button">
                                    {/* <!-- You can hook the "home button" to some JavaScript events or just remove it --> */}
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default LandingHerov3
